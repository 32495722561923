<template>
  <div>
    <content-background-cover
      :background-color='eventMainThemeColorBg'
      class='z-0 main-background'
      :top-offset='0' />
    <div class='py-24 main-background-height'>
      <div class='text-center px-16 py-24 border rounded-lg shadow-md bg-white mx-4 lg:mx-auto lg:w-full lg:max-w-xl'>
        <img :src='eventConfigLogoImageUrl' class='h-8 lg:h-10 inline-block mb-8' />
        <h1 class='text-lg uppercase font-semibold tracking-tight' :style='themeTextColorStyle'>Abstract Book</h1>
        <button 
          @click='downloadAbstractBook'
          class='block w-full px-12 py-4 rounded-lg border uppercase mt-8 text-white font-medium hover:shadow-md' 
          :style='eventMainThemeColorBg'>
          Download Abstract Book
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
// import axios from 'axios'

export default {
  name: 'AbstractBook',
  components: {
    ContentBackgroundCover,
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'themeTextColorStyle',
      'eventMainThemeColorBg',
    ]),
    downloadAbstractUrl() {
      return 'https://gbcc.kr/Abstract'
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    downloadAbstractBook() {
      window.open(this.downloadAbstractUrl, '_blank')
      // axios({
      //   url: this.downloadAbstractUrl, // File URL Goes Here
      //   method: 'GET',
      //   responseType: 'blob',
      // }).then((res) => {
      //   var fileUrl = window.URL.createObjectURL(new Blob([res.data]))
      //   var link = document.createElement('a')
      //   link.href = fileUrl
      //   link.setAttribute('download', 'file.pdf')
      //   document.body.appendChild(link)
      //   link.click()
      // })
    },
  },
  mounted () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractBook'} })
      }
    })
  },
}
</script>

<style lang='scss' scoped>
  .main-background {
    background-color: var(--lightestColor);
    opacity: 0.5;
  }

  .main-background-height {
    height: calc(100vh - 4rem);
  }

  @media (min-width: 1024px) { 
    .main-background-height {
      height: calc(100vh - 12rem);
    }
  }
</style>
